
import request1 from '../util/loginrequest'
import request from '../util/request'
import request2 from '../util/request2'

// 1.获取深度文章列表
export const getHotProject = (params) => {
  return request2({
    url: 'HotProject',
    method: 'post',
    data: {
      period_type: params.period_type || '',
    }

  })
}
// 1.获取深度文章列表
export const getDepthArticlelist = (params) => {
  return request({
    url: '/api/demo/Articles/list',
    method: 'post',
    data: {
      limit: params.limit || '',
      page: params.page || '',
      title: params.title || '',
      id: params.id
    }

  })
}
// 1.热门专题
export const getHsjtopics = (params) => {
  return request({
    url: '/api/demo/hsj/topics',
    method: 'get'
  })
}
// 1.最新指南/学科门类
export const getHsjindustry_new = (params) => {
  return request({
    url: '/api/demo/hsj/industry_new',
    method: 'get'
  })
}
// 1.热搜词
export const getHsjword = (params) => {
  return request({
    url: '/api/demo/hsj/word',
    method: 'get'
  })
}
// 1.获取广告列表
export const getAdvertisinglist = (params) => {
  return request({
    url: '/api/demo/Advertising/find',
    method: 'post'
  })
}
// 1.获取课题申报列表
export const getReportingGuidelinesList = (params) => {
  return request1({
    url: '/api/demo/ProjectGov/list',
    method: 'post',
    data: {
      release_time_seven: params.release_time_seven || '',
      pro_id: params.pro_id || '',
      project_industry_id: params.project_industry_id || '',
      release_time_thirty: params.release_time_thirty || '',
      type_id: params.type_id || '',
      project_status_end: params.project_status_end || '',
      project_status: params.project_status || '',
      help_money_start: params.help_money_start || '',
      help_money_end: params.help_money_end || '',
      limit: params.limit || '',
      page: params.page || '',
      title: params.title || ''
    }

  })
}
// 获取政商招标信息
export const getPoliticsInTheTenderList = (params) => {
  return request1({
    url: '/api/demo/Tender/list',
    method: 'post',
    data: {
      tender_industry_id: params.tender_industry_id || '',
      pro_id: params.pro_id || '',
      tender_status_end: params.tender_status_end || '',
      tender_status: params.tender_status || '',
      release_time_seven: params.release_time_seven || '',
      release_time_thirty: params.release_time_thirty || '',
      help_money_start: params.help_money_start || '',
      help_money_end: params.help_money_end || '',
      limit: params.limit || '',
      page: params.page || '',
      title: params.title || ''
    }
  })
}
// 获取科研项目列表
export const getScientificResearchProjectList = (params) => {
  return request1({
    url: '/api/demo/Project/list',
    method: 'post',
    data: {
      scientific_classification_one: params.scientific_classification_one || '',
      release_time_month: params.release_time_month || '',
      release_time_year: params.release_time_year || '',
      release_time_years: params.release_time_years || '',
      project_organization_fund_country_id: params.project_organization_fund_country_id || '',
      limit: params.limit || '',
      page: params.page || '',
      title: params.title || ''
    }
  })
}
// 获取科研项目列表2
export const getScientificResearchProjectCountt = (params) => {
  return request1({
    url: '/api/demo/Project/countt',
    method: 'post',
    data: {
      scientific_classification_one: params.scientific_classification_one || '',
      release_time_month: params.release_time_month || '',
      release_time_year: params.release_time_year || '',
      release_time_years: params.release_time_years || '',
      project_organization_fund_country_id: params.project_organization_fund_country_id || '',
      limit: params.limit || '',
      page: params.page || '',
      title: params.title || ''
    }
  })
}
// 获取政商招标信息2
export const getPoliticsInTheTenderList2 = (params) => {
  return request1({
    url: 'api/demo/Tender/countt',
    method: 'post',
    data: {
      tender_industry_id: params.tender_industry_id || '',
      pro_id: params.pro_id || '',
      tender_status_end: params.tender_status_end || '',
      tender_status: params.tender_status || '',
      release_time_seven: params.release_time_seven || '',
      release_time_thirty: params.release_time_thirty || '',
      help_money_start: params.help_money_start || '',
      help_money_end: params.help_money_end || '',
      limit: params.limit || '',
      page: params.page || '',
      title: params.title || ''
    }
  })
}