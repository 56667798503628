import request from '../../util/loginrequest'
// 1.注册接口
export const toRegistered = (params) => {
  return request({

    url: '/api/demo/Register/register',
    method: 'post',
    header: {
      'content-type': 'application/json'
    },
    data: {
      mobile: params.mobile || '',
      user_email: params.user_email || '',
      user_pass: params.user_pass || '',
      job: params.job,
      code: params.code
    }

  })
}
// 获取短信验证码
export const getSMScode = (params) => {
  return request({
    url: '/api/demo/Register/aliyun',
    method: 'post',
    data: {
      phone: params
    }

  })
}
// 1.开具发票
export const tomoney = (params) => {
  return request({

    url: '/api/demo/hsj/money',
    method: 'post',
    header: {
      'content-type': 'application/json'
    },
    data: {
      user_id: params.user_id || '',
      invoice_title: params.invoice_title || '',
      code: params.code || '',
      email: params.email,
      notes: params.notes
    }

  })
}

//登录天数
export const getHsjuser = (params) => {
  return request({
    url: '/api/demo/hsj/user',
    method: 'post',
    header: {
      'content-type': 'application/json'
    },
    data: {
      user_id: params.user_id || '',
    }
  })
}
// 1.开具发票
export const invoice = (params) => {
  return request({

    url: '/api/demo/hsj/invoice',
    method: 'post',
    header: {
      'content-type': 'application/json'
    },
    data: {
      user_id: params.user_id || '',
      invoice_title: params.invoice_title || '',
      code: params.code || '',
      email: params.email,
      notes: params.notes
    }

  })
}
export const getHsjhide = (params) => {
  return request({
    url: '/api/demo/hsj/hide',
    method: 'post',
    header: {
      'content-type': 'application/json'
    },
    data: {
      user_id: params.user_id || '',
    }
  })
}
